/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import ContentLoader from 'react-content-loader';
import moment from 'moment';
import dompurify from 'dompurify';
// import { hexToRgb } from '../../utils/helper';
import { strings } from '../../utils/localizedStrings';
import {
  orderInfoHandler,
  returnResonsHandler,
  stepChange,
  returnHandler,
  returnStatusHandler
} from '../../service';
import { currencyMapper, bfl } from '../../utils/entity_mapping';
import { openUrl } from '../../utils/helper';

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

const MyLoader = props => (
  <ContentLoader
    height={160}
    width={400}
    speed={2}
    primaryColor="#f3f3f3"
    secondaryColor="#ecebeb"
    {...props}
  >
    <rect x="150" y="15" rx="3" ry="3" width="250" height="12" />
    <rect x="150" y="40" rx="3" ry="3" width="250" height="12" />
    <rect x="10" y="10" width="100" height="120" />
  </ContentLoader>
);

class RequestStatus extends Component {
  state = {
    key: '',
    waybill: '',
    returnIds: null,
    returnStatus: { timeline: { statuses: [], percentage: [] } },
    progressBarStyle: {},
    animate: false,
    rippleStyle: {},
    showMore: false,
    showWhatsnext: true
  };
  componentDidMount() {
    const { user, returnStatusHandler, location } = this.props;
    const { search } = location;
    const searchParams = new URLSearchParams(search);
    const query = {
      key: searchParams.get('security_key'),
      waybill: searchParams.get('waybill'),
      itemInfoIds: searchParams.get('item_info_id'),
      returnIds: searchParams.get('return_id'),
      domain: user.subdomain
    };

    this.setState({
      key: query.key,
      waybill: query.waybill,
      returnIds: query.returnIds
    });
    this.props.stepChange({ reached: 1, current: 1 });
    returnStatusHandler(query).then(res => {
      if (res.meta.status === 200) {
        const { returnStatus } = this.props;
        if (returnStatus.metadata.isSelfShipped) {
          returnStatus.timeline.percentage = [0, 100];
        }
        this.setState({ returnStatus });
      }
    });

    // if (returnStatus.timeline.statuses.length > 0) {
    //   this.setState({ returnStatus });
    //   const self = this;
    //   setTimeout(function() {
    //     const interval = 0.8;
    //     for (var i = 0; i < returnStatus.timeline.statuses.length; i++) {
    //       if (i <= returnStatus.timeline.reached) {
    //         returnStatus.timeline.statuses[i].style = {
    //           backgroundColor: returnStatus.timeline.timelineColor,
    //           transition: `all ${interval *
    //             (i + 1.2)}s cubic-bezier(.08,.82,.17,1) ${0.4 * i}s`,
    //           border: `1px solid  ${returnStatus.timeline.timelineColor}`
    //         };
    //       }
    //     }
    //     const progressBarStyle = {
    //       width: returnStatus.timeline.percentage[returnStatus.timeline.reached] + '%',
    //       backgroundColor: returnStatus.timeline.timelineColor,
    //       transition: `all ${0.3 * (returnStatus.timeline.reached + 1)}s linear 0s`
    //       // transition: `all ${0.3 * (order.reached +1)}s linear, -webkit-transform 1s ease-in`
    //     };
    //     self.setState({ progressBarStyle, returnStatus });
    //   }, 500);

    //   setTimeout(() => {
    //     const color = hexToRgb(returnStatus.timeline.timelineColor);
    //     this.setState({
    //       animate: true,
    //       rippleStyle: {
    //         backgroundImage: `radial-gradient( circle at 50% 50%, rgba(${
    //           color[0]
    //         }, ${color[1]}, ${color[2]}, 0), rgba(${color[0]}, ${color[1]}, ${
    //           color[2]
    //         }, .01) 20%, rgba(${color[0]}, ${color[1]}, ${color[2]}, 0.25) )`
    //       }
    //     });
    //   }, (returnStatus.timeline.reached + 1) * 750);
    // }
  }

  goToStep = (e, current) => {
    const { step } = this.props.orderInfo;
    if (current > step.reached) {
      step.reached = current;
    }
    step.current = current;
    this.props.stepChange(step);
  };

  goHome = () => {
    const { routePrefix } = this.props.user;
    this.props.stepChange({ reached: 1, current: 1 });
    return sleep(100).then(() => {
      this.props.history.push(
        `${routePrefix ? `/${routePrefix}` : ''}/returns`
      );
    });
  };

  goToMyOrder = () => {
    const { meta_data } = this.props.tracking.theme;
    if (window?.ReactNativeWebView?.postMessage) {
      window.ReactNativeWebView.postMessage(meta_data.my_order_url);
    } else {
      window.location.href = meta_data.my_order_url;
    }
  };

  render() {
    const { orderInfo, returnStatus, tracking, user, page } = this.props;
    const { step } = orderInfo;
    const { config, meta_data } = tracking.theme;
    const sanitizer = dompurify.sanitize;
    const { lang } = user;
    const { udfKeys } = tracking.theme;

    const status = {
      [strings[lang].requestAccepted]: strings[lang]?.requestAcceptedWithDate(
        moment(returnStatus.requestApprovalDate).format('DD-MM-YYYY')
      ),
      [strings[lang].requestCancelled]: strings[lang]?.requestRejected
    };

    const header = returnStatus.timeline.statuses
      .filter((item, i) => {
        return returnStatus.metadata.isSelfShipped && i >= 2 ? false : true;
      })
      .map((item, i) => (
        <li key={i}>
          {item}
          <span
            id={i}
            ref={item}
            data-id={returnStatus.timeline.statuses.length - 1}
            className={`oval ${
              returnStatus.timeline.reached > i
                ? 'reached'
                : returnStatus.timeline.reached === i
                ? 'active'
                : ''
            }`}
            style={
              returnStatus.timeline.reached >= i
                ? {
                    backgroundColor: this.state.returnStatus.timeline.color,
                    borderColor: this.state.returnStatus.timeline.color
                  }
                : {}
            }
          >
            {returnStatus.timeline.reached === i &&
            returnStatus.timeline.statuses.length - 1 !== i &&
            this.state.animate ? (
              <span className="ripple" style={this.state.rippleStyle}>
                <span
                  className="ripple"
                  style={Object.assign(
                    { animationDelay: '3s' },
                    this.state.rippleStyle
                  )}
                >
                  <span
                    className="ripple"
                    style={Object.assign(
                      { animationDelay: '3s' },
                      this.state.rippleStyle
                    )}
                  />
                </span>
              </span>
            ) : null}
          </span>
        </li>
      ));

    const refundStatusTimelineHeaders = returnStatus.refundTimeline?.timeline.map(
      (item, i) => {
        return (
          <li key={i}>
            {item?.status}
            <span
              className={`oval ${
                i <= returnStatus.refundTimeline.reached ? 'active' : ''
              }`}
              style={
                i <= returnStatus.refundTimeline.reached
                  ? {
                      backgroundColor: returnStatus.refundTimeline.color,
                      borderColor: returnStatus.refundTimeline.color
                    }
                  : {}
              }
            ></span>
            <span
              style={{
                position: 'absolute',
                top: 80,
                left: '50%',
                transform: 'translate(-50%)',
                whiteSpace: 'nowrap'
              }}
            >
              {item.timestamp
                ? moment(new Date(item.timestamp)).format('YYYY-MM-DD')
                : null}
            </span>
          </li>
        );
      }
    );

    const isLabel =
      ['hamiltoncollection', 'headsupfortails', 'headsupfortails-test'].indexOf(
        this.props.user.subdomain
      ) > -1;

    const showReturnDate = tracking.theme.meta_data?.udf?.filter(
      item => item.key === 'showReturnDate'
    )?.[0]?.value;
    const returnPickupDate = returnStatus.returnPickupDate;

    const whatsNext = tracking.theme.meta_data?.whatsNext;
    const whatsNextLabel = whatsNext?.returnList?.map(item => item.label);
    const isShantanuNikhil = ['shantanunikhil', 'shantanunikhil-test'].includes(
      user.subdomain
    );
    return (
      <div className={`text-left tss bg-white`}>
        <div className="container-fluid">
          <div
            className={`row row-flex return-container ${
              step.current > 1 ? 'view-min-ht xs-view-min-ht' : ''
            } ${page.appOnly ? 'vh-100 overflow-auto' : ''}`}
          >
            <div
              className={`col-md-16 col-xs-24 order ${
                step.reached > 1 ? 'col-md-8 eq-design' : 'col-md-offset-4'
              } ${step.current !== 1 ? 'show hidden-xs' : ''}`}
            >
              {!page.appOnly || bfl.domains.indexOf(user.subdomain) > -1 ? (
                <div className="row">
                  <div
                    className={`col-md-24 col-xs-24 mrg-top-20 text-right ${
                      step.reached > 1 ? 'col-md-8 eq-design' : ''
                    } ${step.current !== 1 ? 'show hidden-xs' : ''}`}
                  >
                    {meta_data && meta_data.gohome ? (
                      <a className="link-button" onClick={this.goHome}>
                        {strings[lang].goHome}
                      </a>
                    ) : null}
                    {meta_data && meta_data.my_order_url ? (
                      <a
                        className="link-button mrg-left-10"
                        onClick={this.goToMyOrder}
                      >
                        {strings[lang].myOrders}
                      </a>
                    ) : null}
                  </div>
                </div>
              ) : null}
              {this.state.showWhatsnext &&
              whatsNext?.active &&
              whatsNextLabel?.length > 0 &&
              whatsNext?.returnTitle ? (
                <div className="whatsnext">
                  <div className="d-flex justify-between">
                    <p className="fs-16 fw-600">{whatsNext?.returnTitle}</p>
                    <p>
                      <span
                        className="icon-close cursor-pointer"
                        onClick={() =>
                          this.setState({ ...this.state, showWhatsnext: false })
                        }
                      />
                    </p>
                  </div>
                  <ul>
                    {whatsNext?.returnList.map(item => (
                      <li
                        key={item.id}
                        className={`${
                          item.label.length <= 0 ? 'hide' : ''
                        } fw-400 pd-top-10`}
                      >
                        {item.label}
                      </li>
                    ))}
                  </ul>
                </div>
              ) : null}
              <Fragment>
                {/* <div className="col-md-24 col-xs-24 items-center d-flex  md-mrg-top-5 xs-mrg-top-10"> */}
                <h3>{strings[lang].orderStatus}</h3>
                {/* {this.state?.returnIds && (
                    <span className="order-number mrg-left-10 ">
                      Return ID: {this.state.returnIds}
                    </span>
                  )} */}
                {/* </div> */}
                <div className="row mrg-btm-20">
                  <div className="col-md-24 col-xs-24">
                    <section className="status-box">
                      {!returnStatus.metadata.isSelfShipped && (
                        <div className="row xs-mrg-btm-10">
                          <div
                            className={`col-md-8 col-xs-24 mrg-btm-20 xs-mrg-btm-10 ${
                              returnStatus.awb ? 'show' : 'hide'
                            }`}
                          >
                            <div className="row">
                              <div className="col-md-24 col-xs-12">AWB</div>
                              <div className="col-md-24 col-xs-12">
                                {returnStatus.awb}
                              </div>
                            </div>
                          </div>
                          <div
                            className={`col-md-8 col-xs-24 mrg-btm-20 xs-mrg-btm-10 ${
                              returnStatus.courierPartner ? 'show' : 'hide'
                            }`}
                          >
                            <div className="row">
                              <div className="col-md-24 col-xs-12">
                                {strings[lang].courier}
                              </div>
                              <div className="col-md-24 col-xs-12">
                                {returnStatus.courierPartner}
                              </div>
                            </div>
                          </div>
                          <div
                            className={`col-md-8 col-xs-24  ${
                              returnStatus.pickupDate &&
                              returnStatus.metadata.status !== 'REJECTED' &&
                              bfl.domains.indexOf(user.subdomain) === -1
                                ? 'show'
                                : 'hide'
                            } ${
                              showReturnDate === user.subdomain &&
                              returnPickupDate === null
                                ? ''
                                : 'mrg-btm-20 xs-mrg-btm-10'
                            }`}
                          >
                            <div className="row">
                              <div className="col-md-24 col-xs-12">
                                {showReturnDate !== user.subdomain &&
                                returnPickupDate === null ? (
                                  <>
                                    {strings[lang].expectedPickupDate}
                                    <span className="text-red">*</span>
                                  </>
                                ) : null}
                                {showReturnDate === user.subdomain &&
                                  returnPickupDate && (
                                    <>
                                      {strings[lang].estimatedReturnDate}
                                      <span className="text-red">*</span>
                                    </>
                                  )}
                              </div>
                              <div className="col-md-24 col-xs-12">
                                {showReturnDate &&
                                  returnStatus.returnPickupDate &&
                                  (moment(returnStatus.returnPickupDate).format(
                                    'DD-MM-YYYY'
                                  ) ??
                                    null)}
                                {!showReturnDate &&
                                  (isShantanuNikhil
                                    ? moment(returnStatus.pickupDate).format(
                                        'Do MMMM YYYY'
                                      )
                                    : moment(returnStatus.pickupDate).format(
                                        'DD-MM-YYYY'
                                      ))}
                              </div>
                            </div>
                          </div>
                          <div
                            className={`col-md-24 col-xs-24 mrg-btm-20  xs-mrg-btm-10${
                              returnStatus.remark ||
                              returnStatus.metadata.status === 'REJECTED'
                                ? 'show'
                                : 'hide'
                            }
                            ${
                              returnStatus.metadata.status === 'REJECTED' ||
                              ['brandsforless-test', 'brandsforless'].includes(
                                user.subdomain
                              ) ||
                              (showReturnDate === user.subdomain &&
                                returnPickupDate === null)
                                ? ''
                                : 'bd-top-1 pd-top-20'
                            }
                            `}
                          >
                            <div className="row">
                              <div className={`col-md-13 col-xs-24`}>
                                {strings[lang]?.returnStatus ?? 'Return Status'}
                                :{' '}
                                <span
                                  className={`fw-700 mrg-left-20 ${
                                    this.state.returnStatus.timeline.statuses[
                                      this.state.returnStatus.timeline.reached
                                    ] === strings[lang].requestCancelled
                                      ? 'text-failed'
                                      : 'text-success'
                                  }`}
                                >
                                  {status[
                                    this.state.returnStatus.timeline.statuses[
                                      this.state.returnStatus.timeline.reached
                                    ]
                                  ] ?? returnStatus.remark}
                                </span>
                              </div>

                              {returnStatus.rejectionReason && (
                                <div className="col-md-11 bd-left-1 col-xs-24 xs-bd-0 xs-mrg-top-10">
                                  Rejection Reason:{' '}
                                  <span className="text-failed">
                                    {this.state.showMore
                                      ? returnStatus.rejectionReason
                                      : returnStatus.rejectionReason?.slice(
                                          0,
                                          40
                                        )}
                                    {returnStatus.rejectionReason?.length >
                                      40 && (
                                      <span
                                        className=" btn-underline text-failed "
                                        onClick={() =>
                                          this.setState({
                                            showMore: !this.state.showMore
                                          })
                                        }
                                      >
                                        {' '}
                                        ...show{' '}
                                        {this.state.showMore
                                          ? 'less'
                                          : 'more'}{' '}
                                      </span>
                                    )}
                                  </span>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                      <div className="row">
                        {!returnStatus.metadata.isSelfShipped && (
                          <div
                            className={`divider-horizontal mrg-top-0 ${
                              returnStatus.pickupDate ||
                              returnStatus.awb ||
                              returnStatus.courierPartner ||
                              returnStatus.remark
                                ? 'show'
                                : 'hide'
                            }`}
                          ></div>
                        )}
                        <div className="col-md-24 col-xs-24 mrg-btm-20">
                          <ul className="timeline">{header}</ul>
                          <div className="progress">
                            {this.state.returnStatus.timeline.reached ? (
                              <div
                                className="progress-bar"
                                style={{
                                  width:
                                    this.state.returnStatus.timeline.percentage[
                                      this.state.returnStatus.timeline.reached
                                    ] + '%',
                                  backgroundColor: this.state.returnStatus
                                    .timeline.color
                                }}
                              />
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </section>
                    {returnStatus.refundAutomationEnabled &&
                    returnStatus.refundTimeline.timeline.length > 0 ? (
                      <>
                        <h3>Refund Status</h3>
                        <section className="status-box mrg-top-15">
                          <div className="row">
                            <div className="col-md-24 col-xs-24 mrg-btm-30">
                              <ul className="timeline">
                                {refundStatusTimelineHeaders}
                              </ul>
                              <div className="progress">
                                {returnStatus.refundTimeline.reached ? (
                                  <div
                                    className="progress-bar"
                                    style={{
                                      width:
                                        returnStatus.refundTimeline.reached *
                                          100 +
                                        '%',
                                      backgroundColor:
                                        returnStatus.refundTimeline.color
                                    }}
                                  />
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </section>
                      </>
                    ) : null}{' '}
                  </div>
                </div>
                {isLabel && (
                  <>
                    <h3>{strings[lang].shippingLabel}</h3>
                    <div className="row mrg-btm-20 fw-400 fs-15">
                      <div className="col-md-24 col-xs-24">
                        <section className="status-box">
                          <div className="row d-flex items-center">
                            <div className="col-md-18 col-xs-32 md-mrg-top-5 xs-mrg-top-10">
                              <span>Label Status: </span>
                              <span className="text-success mrg-left-20">
                                {returnStatus.metadata.shippingLabel ===
                                null ? (
                                  <> {strings[lang].shippingLabelInProgress}</>
                                ) : (
                                  <> {strings[lang].shippingLabelReady}</>
                                )}
                              </span>
                            </div>
                            <div
                              className={`col-md-6 col-xs-16 md-mrg-top-5 xs-mrg-top-10  shipping-label ${
                                returnStatus.metadata.shippingLabel === null
                                  ? 'hide'
                                  : 'show'
                              }`}
                            >
                              <span className="mrg-left-30">
                                <button
                                  className="ant-button width-90 xs-width-80"
                                  onClick={() =>
                                    openUrl(
                                      returnStatus.metadata.shippingLabel,
                                      '_blank'
                                    )
                                  }
                                >
                                  Download
                                </button>
                              </span>
                            </div>
                          </div>
                        </section>
                      </div>
                    </div>
                  </>
                )}

                {!returnStatus.metadata.isSelfShipped && (
                  <>
                    <h3>{strings[lang].pickupAddress}</h3>
                    <div className="row">
                      <div className="col-md-24 col-xs-24">
                        <section className="status-box">
                          <div className="row">
                            <div className="col-md-12 col-xs-24">
                              <h3 className="mrg-top-0 mrg-btm-10">
                                {returnStatus.metadata.name}
                              </h3>
                              <h4>
                                {returnStatus.metadata.address}
                                <br />
                                {returnStatus.metadata.city}{' '}
                                {returnStatus.metadata.state}{' '}
                                {returnStatus.metadata.country}
                              </h4>
                              <p>{returnStatus.metadata.pincode}</p>
                            </div>
                          </div>
                        </section>
                      </div>
                    </div>
                  </>
                )}
              </Fragment>
              <div className="row item-productInfo">
                {returnStatus.isFetching ? (
                  <div className="col-md-24 col-xs-24">
                    <section className="content preloader">
                      <MyLoader />
                    </section>
                  </div>
                ) : (
                  <Fragment>
                    {returnStatus.items.map((product, i) => (
                      <div key={i} className="col-md-24 col-xs-24">
                        <section
                          className={`content ${
                            orderInfo.action.activeIndex === i ||
                            returnStatus.metadata.isSelfShipped
                              ? 'bd-top-width-1'
                              : ''
                          }`}
                        >
                          <div className="row">
                            <div className="col-md-24 col-xs-24">
                              <div className="item-thumbnail">
                                <a
                                  href={product.url}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <img
                                    src={
                                      product.image ||
                                      (meta_data.image_placeholder ??
                                        (this.props.user.subdomain ===
                                          'truemeds' ||
                                        this.props.user.subdomain ===
                                          'truemeds-test'
                                          ? '/images/medicine.svg'
                                          : '/images/apparel.svg'))
                                    }
                                    alt="product"
                                  />
                                </a>
                              </div>
                              <div className="item-info">
                                <div className="item-productName">
                                  {product.description}
                                </div>
                                <div className="size-block">
                                  <span className="label">SKU </span>
                                  <span className="label">{product.sku}</span>
                                </div>
                                <div className="size-block">
                                  <span className="label">
                                    {strings[lang]?.qty ?? 'Qty'}{' '}
                                  </span>{' '}
                                  <span className="label">
                                    {product.quantity}
                                  </span>
                                </div>
                                {udfKeys?.hideItemPrice  ? null : (
                                  <>
                                    {' '}
                                    <div className="size-block">
                                      {orderInfo.currency ??
                                        currencyMapper(
                                          returnStatus.metadata.country ??
                                            config.country_code
                                        )}{' '}
                                      {product.price}
                                    </div>
                                    <p>{strings[lang].allPriceShownPerItem}</p>
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        </section>
                      </div>
                    ))}
                  </Fragment>
                )}
              </div>
              {meta_data &&
              meta_data.request_pickup_text &&
              !returnStatus.metadata.isSelfShipped ? (
                <div className="row mrg-top-20">
                  <div className="col-md-24 col-xs-24">
                    <div
                      className="info-bar"
                      dangerouslySetInnerHTML={{
                        __html: sanitizer(
                          meta_data && meta_data.request_pickup_text
                        )
                      }}
                    ></div>
                  </div>
                </div>
              ) : config && config.refund_text ? (
                <div className="row mrg-top-20">
                  <div className="col-md-24 col-xs-24">
                    <div
                      className="info-bar"
                      dangerouslySetInnerHTML={{
                        __html: sanitizer(config && config.refund_text)
                      }}
                    ></div>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

RequestStatus.propTypes = {
  user: PropTypes.object,
  tracking: PropTypes.object,
  orderInfo: PropTypes.object,
  page: PropTypes.object,
  location: PropTypes.object,
  history: PropTypes.object,
  orderInfoHandler: PropTypes.func,
  returnResonsHandler: PropTypes.func,
  reasons: PropTypes.object,
  stepChange: PropTypes.func,
  returnHandler: PropTypes.func,
  returnStatus: PropTypes.object,
  returnStatusHandler: PropTypes.func
};

RequestStatus.contextTypes = {
  router: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  user: state.user,
  tracking: state.tracking,
  orderInfo: state.orderInfo,
  returnStatus: state.returnStatus,
  page: state.page
});

const mapDispatchToProps = dispatch => ({
  orderInfoHandler: query => dispatch(orderInfoHandler(query)),
  returnResonsHandler: domain => dispatch(returnResonsHandler(domain)),
  stepChange: step => dispatch(stepChange(step)),
  returnHandler: query => dispatch(returnHandler(query)),
  returnStatusHandler: query => dispatch(returnStatusHandler(query))
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(RequestStatus)
);
